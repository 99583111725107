<template>
  <div class="flex h-full justify-center items-center">
    <VNotification
      :key="JSON.stringify(notification)"
      class="absolute top-22 right-4 w-96"
      :show="!!notification"
      :delay="notification ? notification.delay : 0"
      :type="notification?.type"
      @dismissed="setNotification(null)"
    >
      <template #title>{{ notification?.title }}</template>
      <template #default>
        <template v-if="notification && notification.text">
          {{ notification.text }}
        </template>
      </template>
    </VNotification>

    <slot />
  </div>
</template>

<script lang="ts" setup>
const { notification, setNotification } = useGlobalNotification()
</script>
